import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Form from 'app/components/forms/Form';
import LoginForm, { FormValues, initialValues, validate } from 'app/components/forms/LoginForm';
import { Login } from 'app/components/pages/auth/Login/Login.graphql';
import AuthContext from 'app/contexts/AuthContext';
import { displayError } from 'app/helpers/graphql.client';
import useEventCallback from 'app/hooks/useEventCallback';
import { FORGOTTEN_PASSWORD_ROUTE, HOME_ROUTE, REGISTER_ROUTE } from 'app/routes';
import { LoginMutation, LoginMutationVariables } from 'app/schema.client.types';
import { Formik, FormikConfig } from 'formik';
import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import React, { useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    margin: theme.spacing(1, 0),
  },
}));

interface Props {}

const LoginPage: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const [loginExecute] = useMutation<LoginMutation, LoginMutationVariables>(Login, {
    refetchQueries: ['App_RequestUser'],
  });
  const { login, requestUser } = useContext(AuthContext);

  const onSubmit = useEventCallback<FormikConfig<FormValues>['onSubmit']>(
    (values, { setSubmitting }) => {
      const execute = async () => {
        try {
          const response = await loginExecute({ variables: { email: values.email, password: values.password } });
          setSubmitting(false);
          login(response.data.login);

          // Redirect
          const query = parse(location.search);
          const next = query.next ? (Array.isArray(query.next) ? query.next[0] : query.next) : null;
          navigate(next || HOME_ROUTE);
        } catch (err) {
          setSubmitting(false);
          displayError(err.graphQLErrors, enqueueSnackbar);
        }
      };

      execute();
    },
    [loginExecute, enqueueSnackbar],
  );

  if (requestUser) {
    return <Navigate to={HOME_ROUTE} replace />;
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Prihlásenie
      </Typography>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form className={classes.form} noValidate>
            <LoginForm />
            <div className={classes.submit}>
              {isSubmitting && <LinearProgress className={classes.progress} />}
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                Prihlásiť
              </Button>
            </div>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" component={RouterLink} to={FORGOTTEN_PASSWORD_ROUTE}>
                  Zabudli ste heslo?
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2" component={RouterLink} to={REGISTER_ROUTE}>
                  Nemáte účet? Zaregistrujte sa
                </Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

LoginPage.displayName = 'LoginPage';

export default React.memo<Props>(LoginPage);
