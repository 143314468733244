import TextField from 'app/components/forms/fields/TextField';
import { FormikErrors } from 'formik';
import React from 'react';
import isEmail from 'validator/lib/isEmail';

export interface FormValues {
  email: string;
  password: string;
}

export const initialValues: FormValues = {
  email: '',
  password: '',
};

export function validate(values: FormValues) {
  const errors: FormikErrors<FormValues> = {};
  if (!values.email) {
    errors.email = 'E-mail je povinný';
  }

  if (!values.password) {
    errors.password = 'Heslo je povinné';
  }

  return errors;
}

interface Props {
  className?: string;
}

const LoginForm: React.FunctionComponent<Props> = ({ className }) => (
  <div className={className}>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="email"
      label="E-mail alebo prihlasovacie meno"
      name="email"
      autoComplete="email"
      autoFocus
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Heslo"
      type="password"
      id="password"
      autoComplete="current-password"
    />
  </div>
);

LoginForm.displayName = 'LoginForm';

export default React.memo<Props>(LoginForm);
